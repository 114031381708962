body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Hind',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.frozen-column {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  background-color: white;
  /* or the background color of your table header */
  z-index: 1;
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
  /* Optional: Add a shadow to the right */
}

.rdt_Table {
  overflow: auto;
  /* Ensure horizontal scrolling is enabled */
}